import { Directive } from '@vue/runtime-core';

const getModifierClasses = (baseName: string, modfierArguments: any) => {
    const classList: string[] = [];
    const checkObject = (modifer: any) =>
        Object.keys(modifer)
            .filter((k: string) => modifer[k])
            .map((k: string) => baseName + '--' + k);
    const checkString = (modfier: any) => baseName + '--' + modfier;
    if (Array.isArray(modfierArguments)) {
        for (const modfier of modfierArguments) {
            if (modfier && modfier.constructor === String) {
                classList.push(checkString(modfier));
            } else if (modfier && modfier.constructor === Object) {
                classList.push(...checkObject(modfier));
            }
        }
    } else if (modfierArguments?.constructor === Object) {
        classList.push(...checkObject(modfierArguments));
    } else if (modfierArguments?.constructor === String) {
        classList.push(checkString(modfierArguments));
    }

    return classList;
};

const updateClasses = (node: HTMLElement, oldClasses: string[], newClasses: string[]) => {
    if (oldClasses.length) {
        node.classList.remove(...oldClasses);
    }
    if (newClasses.length) {
        node.classList.add(...newClasses);
    }
};

const updateBindings = (node: HTMLElement, binding: any) => {
    const hasChanges = JSON.stringify(binding.value) !== JSON.stringify(binding.oldValue);
    const baseName = binding.arg;
    if (baseName && hasChanges) {
        const oldClasses = getModifierClasses(baseName, binding.oldValue);
        const newClasses = getModifierClasses(baseName, binding.value);
        updateClasses(node, oldClasses, newClasses);
    }
};

const bemClass: Directive = {
    beforeMount: (node, binding) => {
        updateBindings(node, binding);
    },
    updated: (node, binding) => {
        updateBindings(node, binding);
    },
};

export default bemClass;
