<template>
    <app-header />
    <router-view />
    <app-footer />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { defineAsyncComponent } from '@vue/runtime-core';

@Options({
    components: {
        AppHeader: defineAsyncComponent(() => import('@/components/app-header.vue')),
        AppFooter: defineAsyncComponent(() => import('@/components/app-footer.vue')),
    },
})
export default class MakoWeb extends Vue {}
</script>

<style lang="scss">
@import 'styles/global.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
body {
    --primary: #81d9ed;
    --secondary: #044765;
    --sand: #e5eff4;
    color: #011e32;
}
</style>
