
import { Options, Vue } from 'vue-class-component';
import { defineAsyncComponent } from '@vue/runtime-core';

@Options({
    components: {
        AppHeader: defineAsyncComponent(() => import('@/components/app-header.vue')),
        AppFooter: defineAsyncComponent(() => import('@/components/app-footer.vue')),
    },
})
export default class MakoWeb extends Vue {}
