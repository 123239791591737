import { Translations } from './types';
export const norsk: Translations = {
    app: {
        header: {
            contact: 'Kontakt',
            back: 'Tilbake',
        },
        footer: {
            links: {
                home: 'Forside',
                position: 'Ledig stilling',
            },
        },
        i18n: {
            title: 'Velg språk',
        },
    },
    page: {
        home: {
            intro: {
                title: `Vi er en nystartet virksomhet som leverer software <span class="marker">utviklings</span>- og <span class="marker">supporttjenester</span> til olje og gass sektoren.`,
                lead: `Vårt største fokusområde er å bidra til utviklingen av <span class="marker">banebrytende</span> software løsninger som digitaliserer operasjonsprosedyrer på borerigger og dermed sikrer en <span class="marker">konsistent</span>, <span class="marker">effektiv</span> og <span class="marker">transparent</span> boreoperasjon. <br><br>Produktet er laget av Maersk Drilling A/S i tett samarbeid med noen av de fremste, digitale oljeselskapene.`,
            },
            position: {
                title: 'Software Support Ingeniør',
                lead: 'Vi søker',
                link: 'Finn ut mer',
            },
            persons: {
                title: 'Vi er Mako',
                roles: {
                    boss: 'Daglig leder & konsulent',
                    notboss: 'Styreleder & konsulent',
                    support: 'Software Support Ingeniør',
                    consultant: 'Konsulent',
                },
            },
        },
        position: {
            title: 'Vi søker',
            lead: 'Software Support Ingeniør',
        },
    },
};
