import { reactive } from 'vue';

import { get, forIn } from 'lodash';

import { Translations } from './translations/types';
import { norsk } from './translations/norsk';
import { english } from './translations/english';

export enum LANGUAGE {
    NO = 'NO',
    EN = 'EN',
}

const TRANSLATION_OPTION_KEY = 'itimen-translation-choice';

const translations = {
    NO: norsk,
    EN: english,
};

const data = {
    locale: LANGUAGE.NO,
    translations,
};

export const i18n = reactive(data);

export const translate = <
    T extends Translations,
    Key1 extends keyof T,
    Key2 extends T[Key1] extends string ? never : keyof T[Key1],
    Key3 extends T[Key1][Key2] extends string ? never : keyof T[Key1][Key2],
    Key4 extends T[Key1][Key2][Key3] extends string ? never : keyof T[Key1][Key2][Key3],
    Key5 extends T[Key1][Key2][Key3][Key4] extends string ? never : keyof T[Key1][Key2][Key3][Key4],
    Key6 extends T[Key1][Key2][Key3][Key4][Key5] extends string ? never : keyof T[Key1][Key2][Key3][Key4][Key5],
    Result extends T | T[Key1] extends string
        ? string
        : T[Key1] | T[Key1][Key2] extends string
        ? string
        : T[Key1][Key2] | T[Key1][Key2][Key3] extends string
        ? string
        : T[Key1][Key2][Key3] | T[Key1][Key2][Key3][Key4] extends string
        ? string
        : T[Key1][Key2][Key3][Key4] | T[Key1][Key2][Key3][Key4][Key5] extends string
        ? string
        : T[Key1][Key2][Key3][Key4][Key5] | T[Key1][Key2][Key3][Key4][Key5][Key6] extends string
        ? string
        : T[Key1][Key2][Key3][Key4][Key5][Key6]
>(
    ...keys: [key?: Key1, key?: Key2, key?: Key3, key?: Key4, key?: Key5, key?: Key6]
): Result => {
    return (get(i18n.translations[i18n.locale], keys.join('.')) as unknown) as Result;
};

export const replace = (text: string, replace: { [key: string]: string | number }): string => {
    let replacedText = text;
    forIn(replace, (value, match) => {
        replacedText = replacedText.replace(new RegExp(`{${match}}`, 'g'), value.toString());
    });
    return replacedText;
};

export const setLanguage = (lng: LANGUAGE): void => {
    i18n.locale = lng;
    saveLanguage();
};

export const initTranslations = (): void => {
    const lang = getSavedLanguage();
    if (lang) {
        i18n.locale = lang;
    }
};

const getSavedLanguage = () => {
    const key = window.localStorage.getItem(TRANSLATION_OPTION_KEY);
    if (key && key in LANGUAGE) {
        return key as LANGUAGE;
    }
};

const saveLanguage = () => {
    window.localStorage.setItem(TRANSLATION_OPTION_KEY, i18n.locale);
};
