import { Translations } from './types';
export const english: Translations = {
    app: {
        header: {
            contact: 'Contact',
            back: 'Back',
        },
        footer: {
            links: {
                home: 'Front page',
                position: 'Available positions',
            },
        },
        i18n: {
            title: 'Choose language',
        },
    },
    page: {
        home: {
            intro: {
                title: `We are a newly started company that provides <span class="marker">software development</span> and <span class="marker">support</span> services to the Oil & Gas sector.`,
                lead: `Our main focus is contributing to building <span class="marker">ground-breaking</span> software solutions that digitizes operating procedures on drilling rigs and thus ensures a <span class="marker">consistent</span>, <span class="marker">efficient</span> and <span class="marker">transparent</span> drilling operation. <br><br>The product is created by Maersk Drilling A/S in close close collaboration with leading, digitally mature oil companies.`,
            },
            position: {
                title: 'Software Support Engineer',
                lead: 'We are looking for',
                link: 'Find out more',
            },
            persons: {
                title: 'We are Mako',
                roles: {
                    boss: 'CEO & consultant',
                    notboss: 'Chairman & consultant',
                    support: 'Software Support Engineer',
                    consultant: 'Consultant',
                },
            },
        },
        position: {
            title: 'We are looking for',
            lead: 'Software Support Ingeniør',
        },
    },
};
